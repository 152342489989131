<template>
    <div class="page404">
        <div class="page404__wrapper">
            <div class="page404__error">{{$filters.localize('error')}}</div>
            <div class="page404__number">404</div>
            <div class="page404__img"><img :src="require('@/assets/img/404.png')" alt="404"></div>
            <div class="page404__text">{{$filters.localize('404')}}</div>
        </div>
    </div>
</template>

<script>
export default {
    beforeCreate() {
		setTimeout(() => document.title = this.$route.meta.title || 'Страница не найдена', 0)
	},
}
</script>

<style lang="scss">
.page404
{
    height: 100vh;
    display: flex;
    align-items: center;

    &__wrapper
    {
        width: 100%;
    }

    &__error
    {
        text-align: center;
        font-size: 18px;
    }

    &__number
    {
        text-align: center;
        font-size: 96px;
    }

    &__img
    {
        max-width: 239px;
        margin: 0 auto;

        img
        {
            width: 100%;height: 100%;
            object-fit: cover;
        }

        @include tablet {max-width: 504px;}
        @include desktop {max-width: 648px;}
    }

    &__text
    {
        text-align: center;
        font-size: 14px;
        margin-top: 15px;

        @include tablet {font-size: 18px;}
    }
}

</style>